@keyframes slideDown {
    from {
        top: -20px; }
    to {
        top: 0; } }

@keyframes slideUp {
    from {
        bottom: -100px; }
    to {
        bottom: 20px; } }

@keyframes slideUpAllTheWay {
    from {
        top: 100vh; }
    to {
        top: 1rem; } }

@keyframes slideLeft {
    from {
        right: -20px; }
    to {
        right: 20px; } }

@keyframes transformUp {
    from {
        transform: translateY(20px); }
    to {
        transform: translateY(0); } }

@keyframes fadeIn {
    from {
        opacity: 0; }
    to {
        opacity: 1; } }

@keyframes fadeOut {
    0% {
        opacity: 1; }
    100% {
        opacity: 0; } }

@keyframes flexGrow {
    0% {
        flex-grow: 0.001; }
    100% {
        flex-grow: 1; } }
