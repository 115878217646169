.ResponsiveImage {
	height: 100%;
	width: 100%;
	.DefaultImage {
		display: none;
		width: 100%;
		height: 100%;
		object-fit: cover; }
	.MobileImage {
		width: 100%;
		height: 100%;
		object-fit: cover; } }

@media (min-width: 521px) {
	.ResponsiveImage {
		.DefaultImage {
			display: block; }
		.MobileImage {
			display: none; } } }


