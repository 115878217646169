.InstagramGrid {
	// grid-column: 1 / -1
	margin-left: 0;
	padding-top: 4.5em;
	// min-height: 100vh
	// padding: 0em 2em
	h3 {
		// border-radius: 1rem
		padding: 1.5rem;
		background: transparent;
		color: white;
 }		// background-color: #FFFFFF87
	// iframe
	// 	width: 50% !important
	.flex {
		overflow-x: scroll;
		padding-top: 2em;
		padding-left: 1em;
		&::-webkit-scrollbar {
			display: none; }
		.InstagramCard {
			position: relative;
			// min-width: 250px
			// min-height: 460px
			margin-right: 3em;
			input[type=checkbox] {
				display: none;
				&:checked {
					&~.invisibleLabel {
						height: 100%; }
					&~.Image {
						height: 0%;
						opacity: 0; }
					&~.Description {
						height: 100%;
						border-radius: 0.5rem;
						.Caption {
							overflow: hidden;
							height: calc(21 * 1.2em); }
						.readMoreCaption {
							display: none; } } } }
			.invisibleLabel {
				height: 0%;
				width: 100%;
				position: absolute;
				top: 0;
				z-index: 20; }
			.Image {
				// height: 50%
				// width: 100%
				// transition: opacity 1s
				height: 250px;
				width: 300px;
				opacity: 1;
				a,
				a img {
					display: block;
					width: 100%;
					height: 100%; }
				a {
					img {
						object-fit: cover;
						border-radius: 0.5rem 0.5rem 0rem 0rem; } } }
			.Description {
				// position: absolute
				// bottom: 0
				// height: 50%
				// width: 100%
				width: 300px;
				background-color: white;
				border-radius: 0rem 0rem 0.5rem 0.5rem;
				padding: 0em 0.5em 0em 0.5em;
				transition: height 0.4s;
				box-sizing: border-box;
				p, label {
					color: black; }
				.ProfileName {
					padding: 0.5em 0em 0.5em 0em; }
				.Caption {
					line-height: 1.2em;
					height: calc(8 * 1.2em);
					overflow: hidden; }
				label {
					position: absolute;
					bottom: 1em;
					left: 0.5em;
					text-decoration: underline; } } } } }

@media (min-width: 835px) {
	.InstagramGrid {
		.flex {
			flex-wrap: wrap;
			.InstagramCard {
				margin-bottom: 2em;
				.Image {
					width: 350px;
					height: 300px; }
				.Description {
					width: 350px; } } } } }
