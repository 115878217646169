@import '../util/mixins';

.Message {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, 0);
    background: rgba(#FFF, 0.9);
    border-radius: 0.5rem;
    padding: 1rem;
    line-height: 2rem;
    z-index: 1000000;
    max-width: 250px;
    pointer-events: none;
    text-align: center;
    transition: opacity 1s;
    white-space: pre-line;
    box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.5);
    &.hide {
        opacity: 0; }
    button {
        position: absolute;
        top: -5px;
        right: -5px;
        z-index: 1000001;
        pointer-events: initial;
        @include button-close; } }


@media (min-width: 576px) {
    .Message {
        top: 1rem; } }

// Target firefox mobile portrait and landscape
@media (max-width: 900px) {
	@-moz-document url-prefix() {
        .Message {
            font-size: 12px; } } }
