.Popup {
	&.mapboxgl-popup-anchor-bottom,
	&.mapboxgl-popup-anchor-bottom-left,
	&.mapboxgl-popup-anchor-bottom-right, {
		.mapboxgl-popup-tip {
			border-top-color: #5A2929; } }
	&.mapboxgl-popup-anchor-top,
	&.mapboxgl-popup-anchor-top-right,
	&.mapboxgl-popup-anchor-top-left, {
		.mapboxgl-popup-tip {
			border-bottom-color: #5A2929; } }
	&.mapboxgl-popup-anchor-right {
		.mapboxgl-popup-tip {
			border-left-color: #5A2929; } }
	&.mapboxgl-popup-anchor-left {
		.mapboxgl-popup-tip {
			border-right-color: #5A2929; } }
	.mapboxgl-popup-content {
		padding: 20px;
		background-color: #5A2929;
		.close {
			position: absolute;
			top: -8px;
			width: 23px;
			height: 23px;
			right: -8px;
			-webkit-appearance: none;
			border: none;
			background-color: #C8C8C8;
			border-radius: 13px;
			cursor: pointer; }
		.popup-text {
			color: #ffffff;
			.popup-description {
				margin-top: 10px; } }
		.popup-buttons {
			margin-top: 35px;
			display: flex;
			justify-content: space-between;
			button {
				background-color: #437BA0;
				height: 35px;
				color: #FFFFFF;
				border: none;
				font-weight: bold;
				letter-spacing: 1px;
				font-size: 1.1em;
				border-radius: 2px; }
			.popup-explore {
				width: 60%;
				button {
					width: 100%; } }
			.popup-next {
				width: 35%; } } } }





