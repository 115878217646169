.MapWrapper {
	height: 100%; }

.Map {
	z-index: 90;
	height: 100%;
	width: 100%;
	overflow: hidden;
	position: relative; }

.ZoomOverlay {
	position: absolute;
	height: 100%;
	width: 100%;
	background: black;
	opacity: 0.5;
	z-index: 99;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white; }


.Floaters {
	position: absolute;
	z-index: 10;
	right: calc(250px + 2.2rem);
	bottom: 1rem;
	display: flex;
	flex-direction: row;
	> * {
		animation: fadeIn 2s, slideUp 1s forwards;
		margin-left: 10px;
		button {
			color: white;
			border-color: white !important; }
		&.FullscreenButton {
			button {
				img {
					height: 16px;
					transform: translateY(2px); } } } } }


.mapboxgl-map {
	width: 100% !important;
	position: fixed; }

@media (max-width: 575px) {
	.ZoomOverlay {
		> div {
			margin-bottom: 50%; } }
	.Floaters {
		right: 20px;
		bottom: calc(200px + 3.1rem);
		animation: fadeIn 2s; } }

@media (min-width: 576px) and (min-height: 600px) {
	.ZoomOverlay {
		> div {
			margin-right: 25%; } } }

@media (min-width: 1920px) {
	.Floaters {
		right: calc(350px + 2.2rem + 16px); } }

// Target firefox mobile portrait and landscape
@media (max-width: 900px) {
	@-moz-document url-prefix() {
		.Floaters {
			bottom: calc(250px + 24px); } } }
