.Editorial {
	background-attachment: fixed;
	background-repeat: no-repeat;
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none; }
	.textContainer {
		grid-column: 1 / -1;
		padding: 100vh 1.5em;
		.title {
			color: white; }
		.content-wrapper {
			padding: 7em 0;
			&:last-child {
				padding: 7em 0em 0em 0em; }
			h3 {
				color: white;
				padding-bottom: 1.5em; }
			p {
				color: white;
				font-size: 1.1em;
				line-height: 1.5em; }
			img {
				padding-top: 2em;
				width: 100%; } } } }

@media (min-width: 900px) {
	.Editorial {
		.textContainer {
			padding-left: 3em;
			padding-right: 3em;
			grid-column: 1 / 8; } } }


@media (min-width: 1280px) {
	.Editorial {
		.textContainer {
			grid-column: 1 / 9; } } }
