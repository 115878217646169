@mixin button-style {
    -webkit-appearance: none;
    background: none;
    border: 1px solid #41403E;
    border-radius: 4px;
    // border-top-left-radius: 255px 15px
    // border-top-right-radius: 15px 225px
    // border-bottom-right-radius: 225px 15px
    // border-bottom-left-radius: 15px 255px
    cursor: pointer;
    // font-family: Averia Libre, Helvetical, Arial, sans-serif
    padding: 0.5rem 1rem;
    &:focus {
        outline: none; } }

@mixin button-style-cta {
    width: 200px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 7px;
    border: none;
    margin-top: 1rem;
    // font-family: Averia Libre, Helvetical, Arial, sans-serif
    font-size: 1.1rem;
    font-weight: bold;
    transition: all 0.3s ease 0s;
    background: rgba(#437BA0, 0.7);
    color: white;
    cursor: pointer;
    &:disabled {
        background-color: #b4b4b4; }
    &:hover:not(:disabled) {
        background-color: rgba(#437BA0, 0.9); } }

@mixin button-close {
    background: rgba(255, 0, 0, 0.4) !important;
    color: white !important;
    padding: 1px 6px !important; }
