.Slideshow {
	.fadeOut {
		animation: fadeOut;
		animation-duration: 0.5s; }
	.slide-container {
		grid-area: 1 / 1 / -1 / -1;
		opacity: 0;
		z-index: 0;
		position: fixed;
		transition: opacity 0.5s;
		&.active {
			z-index: 1;
			opacity: 1;
			position: relative; }
		&.transition {
			z-index: 2;
			opacity: 1; }
		.image-slide {
			width: 100%;
			height: 100%;
			object-fit: cover; } } }
