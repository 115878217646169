@import './layout/grid';
@import '/util/mixins';
@import '/util/symbols';
@import '/util/tools';
@import '/util/animations';

// @import '/fonts/averia'
// @import url('https://fonts.googleapis.com/css2?family=Averia+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@import "./components/Map";
@import "./components/Explore";
// @import ./components/ExploreControls
@import "./components/Popup";
@import "./components/icon";
@import "./components/Scroll";
@import "./components/ScrollCards";
@import "./components/Editorial";
@import "./components/HTMLVideo";
@import "./components/Controls";
@import "./components/Slideshow";
@import "./components/FluidScroll";
@import "./components/IntroScreen";
@import "./components/FavoriteHeart";
@import "./components/ActionMenu";
@import "./components/Message";
@import "./components/ScrollContent";
@import "./components/ResponsiveImage";
@import "./components/Loader";
@import "./components/EmailOverlay";
@import "./components/TipOverlay";
@import "./components/InstagramGrid";
@import "./components/SignUpModal";
@import "./components/AccountModal";
@import "./components/SandboxModal";
@import "./components/LineAnimation";
@import "./components/IntroMessage";
@import "./components/AboutModal";
@import "./components/MysteryModal";

html, body, #root {
	height: 100%;
	background: black;
	&>* {
		height: 100%; } }

body {
	margin: 0;
 }	// font-family: Averia Libre, Helvetical, Arial, sans-serif

h1, h2, h3, h4, h5, h6 {
	margin: 0;
 }	// font-family: Averia Libre, Helvetical, Arial, sans-serif

h1, h2, h3, h4 {
	font-weight: 700;
	font-size: 1.5rem; }

h3, h4 {
	// font-style: italic
	font-size: 1.2rem; }

h5, h6 {
	font-weight: 400;
	font-size: 1rem; }

p {
	margin: 0;
 }	// font-family: Averia Libre, Helvetical, Arial, sans-serif

// span
/// font-family: Averia Libre, Helvetical, Arial, sans-serif

button:not(.CTA) {
	@include button-style; }

button.CTA {
	@include button-style-cta; }

.overflow-hidden {
	overflow: hidden; }

.mapboxgl-ctrl-attrib, .mapboxgl-ctrl-logo {
	display: none !important; }

a, button {
	-webkit-tap-highlight-color: transparent; }

.overlay {
	position: absolute;
	background-color: #00000054;
	height: 100%;
	width: 100%; }

.modal {
	height: 100%;
	width: 100%;
	position: absolute;
	align-items: center;
	justify-content: center;
	display: flex;
	z-index: 1010; }
button.Close {
	background: url("/assets/icons/close.svg") center center/25px 25px no-repeat, rgba(255, 255, 255, 0.4) !important;
	color: white;
	width: 1rem;
	height: 1rem;
	position: absolute;
	z-index: 10;
	border: none !important;
	padding: 1rem;
	border-radius: 1rem; }

input[type='email'], input[type='text'], textarea {
	padding: 12px;
	font-size: 14px;
	border: 1px solid #41403E !important; }

// Only show for...
// @media (max-width: 575px)
// 	.screen-md, .screen-lg
// 		visibility: hidden !important
// 	.screen-sm
// 		visibility: visible !important

// @media (min-width: 576px) and (max-width: 991px)
// 	.screen-sm, .screen-lg
// 		visibility: hidden !important
// 	.screen-md
// 		visibility: visible !important

// @media (min-width: 992px)
// 	.screen-sm
// 		visibility: hidden !important
// 	.screen-md
// 		visibility: hidden !important
// 	.screen-lg
// 		visiblity: visible !important

// @media (max-width: 575px)
// 	html, body, #root
// 		position: fixed
// 		width: 100%

// @media (max-width: 575px)
// 	.screen-md, .screen-lg
// 		visibility: hidden !important
// 	.screen-sm
// 		visibility: visible !important

// @media (min-width: 576px) and (max-width: 991px)
// 	.screen-sm, .screen-lg
// 		visibility: hidden !important
// 	.screen-md
// 		visibility: visible !important

// @media (min-width: 992px)
// 	.screen-sm
// 		visibility: hidden !important
// 	.screen-md
// 		visibility: hidden !important
// 	.screen-lg
// 		visiblity: visible !important

// @media (max-width: 575px)
// 	.screen-md, .screen-lg
// 		visibility: hidden !important
// 		width: 0 !important
// 		height: 0 !important
// 	.screen-sm
// 		visibility: visible !important
// 		width: auto !important
// 		height: auto !important

// @media (min-width: 576px) and (max-width: 991px)
// 	.screen-sm, .screen-lg
// 		visibility: hidden !important
// 		width: 0 !important
// 		height: 0 !important
// 	.screen-md
// 		visibility: visible !important
// 		width: auto !important
// 		height: auto !important

// @media (min-width: 992px)
// 	.screen-sm, .screen-md
// 		visibility: hidden !important
// 		width: 0 !important
// 		height: 0 !important
// 	.screen-lg
// 		visiblity: visible !important
// 		width: auto !important
// 		height: auto !important

@media (max-width: 900px) {
	@-moz-document url-prefix() {
		h1, h2, h3, h4 {
			font-size: 18px; }
		h3, h4 {
			font-size: 16px; }
		p, span {
			font-size: 12px; } } }
