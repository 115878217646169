.TipOverlayWrapper {
	min-height: 100vh;
	min-width: 100vw;
	display: none;
	position: absolute;
	// align-items: center
	justify-content: center;
	&.visible {
		display: flex;
		z-index: 1000; }
	.TipModalWrapper {
		position: relative;
		margin-top: 2rem;
		// max-height: 90vh
		.Close {
			top: -16px;
			right: -16px; }
		.DonorboxWrapper {
			// max-height: calc(100vh - 2rem)
			// max-width: calc(100vw - 2rem)
 } } }			// overflow: scroll

iframe[name=donorbox] {
	max-width: 100vw;
	min-width: 250px;
	max-height: none !important; }
