.flex {
	display: flex; }

.justify-center {
	justify-content: center; }

.justify-around {
	justify-content: space-around; }

.justify-between {
	justify-content: space-between; }

.align-center {
	align-items: center; }

.link {
	text-decoration: underline;
	color: #6a5ed4;
	font-size: .8rem;
	font-weight: bold;
	cursor: pointer; }
