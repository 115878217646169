.EmailOverlayWrapper {
	height: 100%;
	width: 100%;
	display: none;
	position: absolute;
	align-items: center;
	justify-content: center;
	&.visible {
		display: flex;
		z-index: 1000; }
	.EmailModalWrapper {
		position: relative;
		// .Close
		// 	background: rgba(255, 0, 0, 0.4)
		// 	color: white
		// 	padding: 1px 6px
		// 	position: absolute
		// 	top: -7px
		// 	right: -7px
		// 	z-index: 10
		button[type='submit'] {
			border-radius: 7px !important;
			background-color: #7f7ecc !important; }
		.form-input-element {
			border: 1px solid #41403E !important;
			border-top-left-radius: 255px 15px !important;
			border-top-right-radius: 15px 225px !important;
			border-bottom-right-radius: 225px 15px !important;
			border-bottom-left-radius: 15px 255px !important;
			background-color: transparent !important; }
		button[type='submit'] {
			border-radius: 7px !important;
			background-color: #e2dede !important;
			&:hover {
				background-color: #a7a7a7 !important; } } } }

