.Controls {
	position: fixed;
	z-index: 1001;
	bottom: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding: 0 1rem;
	height: 40px;
	box-sizing: border-box;
	animation: slideUp 2s;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	img {
		-webkit-user-select: none;
		-webkit-touch-callout: none; }
	& > * {
		pointer-events: auto;
		display: flex;
		flex-direction: column;
		align-self: flex-end; }
	button {
		&:focus {
			outline: none; } }
	.Back {
		display: block;
		text-decoration: none;
		position: fixed;
		animation: slideUpAllTheWay 2s forwards;
		animation-delay: 2s;
		z-index: 1001;
		button {
			diplay: flex;
			flex-direction: column;
			border: none;
			color: white;
			font-size: 1em;
			opacity: 1;
			padding: 0;
			margin-left: 1rem;
			img {
				height: 2rem; }
			p {
				opacity: 0.8;
				transform: translateY(2px);
				font-size: 0.5rem;
				display: none; } } }
	.ExitThree60 {
		margin-left: 1em;
		background-color: transparent;
		width: auto;
		border-color: white;
		color: white;
		opacity: 0.8;
		h6 {
			// border-radius: 1rem
			padding: 3px;
 } }			// background-color: #FFFFFF87
	button.next, button.prev {
		background-color: rgba(0,0,0,.31);
		box-shadow: 0px 0px 3px 0px #ffffff71;
		border-radius: 3px;
		height: 30px;
		color: white;
		font-size: 1em;
		margin-left: 1em; }
	button.next {
		img {
			transform: scaleX(-1); } }
	.StartPlayButton {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		animation: fadeIn 2s;
		z-index: -1;
		background: rgba(0,0,0,0.6);
		// pointer-events: none
		button {
			// pointer-events: auto
			width: 200px;
			border: none;
			padding: 3rem;
			transform: translateY(-40px);
			background: radial-gradient(circle 75px, rgba(255,255,255, 0.25), rgba(255,255,255, 0));
			&:focus {
				background: radial-gradient(circle 75px, rgba(255,255,255, 0.25), rgba(255,255,255, 0)); }
			p {
				color: white;
				margin-bottom: 0.5rem; }
			img {
				height: 3rem; } } }
	.ProgressIndicator {
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		height: 100%;
		display: flex;
		flex-direction: column;
		width: 15px;
		.IndicatorSegment {
			width: 100%;
			padding: 5px 5px;
			box-sizing: border-box;
			.IndicatorContainer {
				border-radius: 4px;
				height: 100%;
				background-color: rgba(185,185,185,0.2);
				transition: background-color 1s ease;
				cursor: pointer;
				.Indicator {
					background-color: rgba(255,255,255,0.8);
					transition: background-color 1s ease;
					border-radius: inherit; } } } }
	.HoldForSound {
		width: 75px;
		height: 75px;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0.8;
		position: fixed;
		right: 50px;
		top: 100px;
		border: 1px solid white;
		padding: 1rem;
		border-radius: 50%;
		.ActiveBackground {
			position: absolute;
			width: 0;
			height: 0;
			background: rgba(255,255,255,0.2);
			border-radius: 50%;
			transition: width 1s, height 1s; }
		&.active {
			.ActiveBackground {
				width: 100%;
				height: 100%; } }
		p {
			color: white;
			pointer-events: none;
			text-align: center;
			font-size: 8px; }
		img {
			width: 36px; } }
	.VideoControls {
		margin-left: auto;
		& > * {
			margin-left: 1rem; }
		.ControlButton {
			margin-left: 1rem;
			background-color: transparent;
			height: 32px;
			width: 32px;
			border: none;
			padding: 0;
			margin-top: 16px;
			opacity: 0.5;
			transition: opacity 1s linear;
			-webkit-backface-visibility: hidden;

			img {
				width: 32px;
				height: 32px;
				opacity: 1; } }
		.HTMLVideoControls {
			z-index: 1000;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.Play, .Pause {
				height: 32px;
				width: 32px;
				display: flex;
				justify-content: center;
				align-items: center;
				opacity: 0.5;
				transition: opacity 1s linear;
				-webkit-backface-visibility: hidden;
				button {
					height: 32px;
					width: 32px;
					border: none;
					padding: 0; }
				img {
					height: 32px;
					width: 32px; } }
			.Scrubber {
				position: relative;
				width: 5px;
				height: 200px;
				margin-bottom: 2rem;
				border: 1px solid rgba(255,255,255,0.2);
				border-radius: 3px;
				transform: translateX(4px);
				.Duration, .Elapsed {
					position: absolute;
					height: 100%;
					top: 0; }
				.Duration {
					width: 100%;
					height: 100%;
					margin: 0;
					padding: 0;
					opacity: 0;
					cursor: pointer;
					transform: rotateX(180deg);
					-webkit-appearance: slider-vertical; }
				.Elapsed {
					background: white;
					width: 100%;
					opacity: 0.5;
					transition: opacity 1s linear;
					-webkit-backface-visibility: hidden;
					border-radius: 2px; } } } } }

.ScrollMore {
	position: fixed;
	bottom: -32px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	flex-direction: column;
	text-align: center;
	color: rgba(255,255,255,0.8);
	pointer-events: none;
	p {
		opacity: 0;
		-webkit-backface-visibility: hidden;
		font-size: 0.75rem;
		animation: fadeOut 10s; }
	.LineAnimation {
		margin-top: 8px;
		height: 64px;
		.Line {
			height: 32px; } } }

// For landscape mobile:
@media (orientation: landscape) and (max-height: 500px) {
	.Controls {
		.VideoControls {
			margin-bottom: 50px;
			.HTMLVideoControls {
				transform: rotate(-90deg);
				position: absolute;
				bottom: -83px;
				right: 116px;
				width: 0;
				height: 200px;
				justify-content: flex-end;
				.Play, .Pause {
					transform: rotate(90deg); }
				.Scrubber {
					margin-bottom: 1rem;
					transform: translateX(-4px); } } } }
	@-moz-document url-prefix() {
		.Controls {
			.VideoControls {
				.HTMLVideoControls {
					right: 120px; } } } } }

@media (min-width: 576px) and (min-height: 600px) {
	.Controls {
		.VideoControls {
			.ControlButton {
				height: 32px;
				width: 32px; } } } }

.ScrollContent.mouseActive {
	.IndicatorSegment {
		.IndicatorContainer {
			background-color: rgba(185,185,185,0.7);
			.Indicator {
				background-color: rgba(255,255,255,1); } } }
	.VideoControls {
		.ControlButton {
			opacity: 1; }
		.HTMLVideoControls {
			.Play, .Pause {
				opacity: 1; }
			.Scrubber {
				.Elapsed {
					opacity: 0.8; } } } } }
