.ScrollCards {
	position: fixed;
	z-index: 100;
	bottom: 0;
	margin-bottom: 2rem;
	padding-top: 1.1rem;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	overflow-x: scroll;
	overflow-y: hidden;
	scroll-snap-type: x mandatory;
	-webkit-overflow-scrolling: touch;

	&.init {
		.Card {
			animation: fadeIn 1s forwards;
			animation-iteration-count: 1; } }

	.Card {
		scroll-snap-align: center;
		scroll-snap-stop: always;
		height: 200px;
		min-width: 70vw;
		margin-left: 1rem;
		position: relative;
		border-radius: 0.5rem;
		opacity: 0;
		box-shadow: -2px -2px 16px rgba(0,0,0,0.4);
		&.active {
			.Content {
				.Description {
					background-color: #FFF; }
				.Image {
					a {
						&>span {
							opacity: 1;
							width: calc(70px + 0.5rem); } } } } }
		&.Email {
			min-height: unset;
			background-color: rgba(255, 255, 255, 0.8);
			border-radius: 7px;
			padding: 1rem;
			.TextWrapper {
				span {
					font-size: 64px;
					float: left;
					margin-right: 6px;
					margin-bottom: 6px;
					line-height: 64px; }
				p {
					margin-bottom: 1rem;
					font-size: 0.75rem;
					text-align: justify; } }
			.SignIn {
				margin: 1rem 0;
				padding: 0;
				text-decoration: underline;
				color: #6a5ed4;
				font-size: .8rem;
				font-weight: bold;
				cursor: pointer; }
			form {
				.InputWrapper {
					position: relative;
					input[type='email'] {
						width: 100%;
						padding-right: calc(46.4px + 12px);
						&:focus {
							outline: none; } }
					button {
						position: absolute;
						width: 46.4px;
						height: 46.4px;
						top: 0;
						right: 0;
						min-width: unset;
						margin: 0;
						padding: 0;
						color: white;
						border-radius: 0;
						img {
							height: 24px;
							transform: translateY(2px); } } } } }
		&.Intro {
			background: rgba(255,255,255,0.8);
			padding: 1rem 1rem;
			box-sizing: border-box;
			min-height: 30vh;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			&>div {
				display: flex;
				flex-direction: column;
				min-height: 100%; }
			input[type=checkbox] {
				&:checked {
					&~.Instructions {
						display: none; }
					&~.Settings {
						min-height: 100%;
						& .ToggleSettings {
							display: none; }
						& .Done {
							display: inline-block; } }
					&~.Settings .Options {
						display: block; } } }
			.Instructions {
				flex: 1;
				&>div {
					display: flex;
					align-items: center;
					margin-top: 26px;
					padding-left: 20px;
					&:first-child {
						margin-top: 8px; }
					&>div:first-child {
						width: 2rem; } }
				p {
					font-size: 0.8rem; } }
			img {
				height: 20px;
				display: block; }
			.Settings {
				display: flex;
				flex-direction: column;
				.Done {
					display: none; }
				.Options {
					display: none;
					flex: 1;
					button {
						display: flex;
						flex-direction: row;
						align-items: center;
						border: none;
						margin-top: 16px;
						&:first-child {
							margin-top: unset; }
						span {
							font-size: 0.8rem; } } }
				.SeeOptions {
					cursor: pointer;
					button {
						display: flex;
						flex-direction: row;
						align-items: center;
						padding: 0.5rem 1rem;
						margin: 0 auto;
						pointer-events: none; } }
				img {
					display: inline-block;
					margin-right: 0.5rem;
					height: 20px; } } }

		.CardNumber {
			background: #437ba0;
			color: white;
			border-radius: 50%;
			border: 2px solid white;
			// box-shadow: 0px 0px 6px rgba(#FFF, 0.4)
			display: inline-block;
			width: 3rem;
			height: 3rem;
			font-size: 1.5rem;
			font-weight: 700;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: -1rem;
			left: -1rem;
			z-index: 101; }

		.Content {
			height: 100%;
			.Image {
				width: 100%;
				height: 100%;
				overflow: hidden;
				position: relative;
				transition: height 0.4s;
				border-radius: 5px;
				transition: opacity 1s;
				h4.MobileTitle {
					position: absolute;
					max-width: calc(100% - 40px);
					padding: 1rem;
					color: white;
					text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
					text-transform: uppercase; }
				&>img {
					width: 100%;
					display: block;
					height: 100%;
					object-fit: cover; }
				.FavoriteHeart {
					position: absolute;
					top: 0.5rem;
					right: 0.5rem; }
				a {
					position: absolute;
					bottom: 1rem;
					right: 0;
					display: flex;
					flex-direction: row;
					align-items: center;
					padding: 0.5rem;
					background-color: rgba(#3e3e3e, 0.7);
					color: #FFFFFF;
					border: none;
					font-weight: bold;
					letter-spacing: 1px;
					font-size: 1.1em;
					text-decoration: none;
					&>span {
						overflow: hidden;
						width: 0px;
						opacity: 0;
						transition: width ease-in-out 0.8s, opacity ease-in-out 0.8s; }
					&>img {
						height: 2rem; } }
				label {
					position: absolute;
					bottom: 1rem;
					left: 0;
					color: white;
					padding: 0.5rem;
					text-decoration: underline; } }
			.Description {
				position: absolute;
				bottom: 0;
				height: 0px;
				width: 100%;
				overflow: hidden;
				z-index: 99;
				transition: height 0.4s;
				box-sizing: border-box;
				background: rgba(255,255,255,0.8);
				border-radius: 5px;
				scrollbar-width: none;
				-ms-overflow-style: -ms-autohiding-scrollbar;
				&::-webkit-scrollbar {
					display: none; }
				.Tags {
					padding: 0 0.5rem;
					margin-bottom: 0.5rem;
					span {
						display: inline-block;
						padding: 5px;
						margin-right: 5px;
						margin-top: 5px;
						background: blue;
						color: white;
						font-size: 0.75rem;
						border-radius: 8px; } } }
			.Description, &.Intro {
				h4 {
					margin: 0;
					margin-top: 1rem;
					padding: 0.5rem;
					text-transform: uppercase; }
				p {
					display: none;
					line-height: 1.4rem;
					padding: 0 0.5rem;
					padding-bottom: 0.5rem;
					margin: 0;
					// display: -webkit-box
					// -webkit-line-clamp: 4
					// -webkit-box-orient: vertical
					line-clamp: 4;
					box-orient: verticle;
					overflow: hidden; } } }
		input[type='checkbox'] {
			position: absolute;
			bottom: 0;
			margin: 0;
			height: 200px;
			width: 100%;
			display: none;
			// &:checked ~ label
			// 	height: 100%
			&:checked ~ .Content {
				.Description {
					height: 100%;
					z-index: 1000;
					overflow: scroll;
					.InvisibleLabel {
						height: 100%; }
					p {
						display: block;
						-webkit-line-clamp: unset !important;
						line-clamp: unset !important;
						// -webkit-box-orient: vertical
 } } }						// overflow: hidden
			&:checked ~ .Content .Image {
				// height: 0
				opacity: 0; } }
		.InvisibleLabel {
			z-index: 100;
			height: 0%;
			width: 100%;
			position: absolute;
			top: 0; } }
	.read-more, .read-details {
		cursor: pointer;
		text-shadow: 1px 1px 4px rgba(0,0,0,0.7); } }

@media (max-width: 567px) {
	.ScrollCards {
		.Card {
			&.Intro {
				width: 70vw; }
			&:last-of-type:after {
				content: "";
				display: block;
				position: absolute;
				right: -1rem;
				width: 1rem;
				height: 1px; }
			&.Email {
				min-width: 250px;
				.moosend-main-form-wrapper {
					border-radius: 8px; } } } } }

@media (min-width: 568px) {
	.ScrollCards {
		width: auto;
		height: 100%;
		flex-direction: column;
		right: 0;
		margin-bottom: 0;
		padding-right: 1rem;
		overflow-y: scroll;
		padding-top: 0;
		padding-left: 1.1rem;
		scroll-snap-type: y proximity;
		.Card {
			min-width: unset;
			width: 250px;
			min-height: 50vh;
			margin: 1rem 0;
			position: relative;
			&.Intro {
				min-height: 200px;
				.Instructions {
					p {
						font-size: 0.8rem; } }
				// .Settings
				// 	.Options
				// 		button
 }				// 			margin-top: 1.5rem
			.Content {
				.Image {
					// height: auto
					width: 100%;
					transition: opacity 1s; } }
			input[type='checkbox'] {
				&:checked ~ .Content .Image {
					opacity: 0; }
				&:checked ~ .Content .Description {
					height: 100%; } } } } }

@media (min-width: 568px) and (min-height: 520px) {
	.ScrollCards {
		.Card {
			width: 250px;
			min-height: 400px;
			// min-height: 500px
			// &.active
			// 	transition: border-color 1s
			// 	border-left: 3px solid #3e3e3e
			// 	border-radius: 0.75rem
			//CardNumber
			//transition: border-color 1s
			//border-color: #3e3e3e
			&.Intro {
				height: auto; }
			.Content {
				display: flex;
				flex-direction: column;
				.Image {
					flex: 1;
					// height: calc(100% - 100px)
					// min-height: 250px
					border-radius: 0.5rem;
					&>img {
						height: 100%;
						min-height: 250px;
						// width: unset
						width: 100%;
						object-fit: cover; }
					h4.MobileTitle {
						display: none; } }
				.Description {
					height: auto;
					position: initial;
					bottom: 0;
					border-radius: 0 0 0.5rem 0.5rem;
					width: 100%;
					color: white;
					h4 {
						margin-top: 0.25em;
						padding-right: 50px; } } }
			input[type='checkbox'] {
				&:checked ~ .Content .Description {
					height: 100%;
					border-radius: 0.5rem;
					color: black; } } } } }


@media (min-width: 1920px) {
	.ScrollCards {
		.Card {
			width: 350px; } } }


// @media (min-width: 576px) and (min-height: 600px)
//     .ScrollCards
//         .Card.Intro


// @media (min-width: 576px)
//     .ScrollCards
//         z-index: 100
//         position: absolute
//         right: 0
//         bottom: auto
//         margin-right: 1rem
//         height: 100%
//         flex-direction: column
//         overflow-y: scroll
//         overflow-x: hidden

//         .Card
//             min-height: 90vh
//             max-height: 500px
//             width: 250px
//             margin: 1rem 0
//             background: rgba(255,255,255,0.9)
//             // -webkit-box-shadow: 0px 0px 10px 0px rgba(255,255,255,1)
//             // -moz-box-shadow: 0px 0px 10px 0px rgba(255,255,255,1)
//             // box-shadow: 0px 0px 10px 0px rgba(255,255,255,1)
//             border-radius: 0.5rem
//             box-sizing: border-box
//             position: relative

//             &:first-child
//                 &>div:first-child
//                     padding: 1rem

//             .Image
//                 width: 100%
//                 max-height: 50%
//                 overflow: hidden
//                 border-radius: 0.5rem 0.5rem 0 0
//                 position: relative
//                 transition: opacity 0.2s
//                 &>img
//                     width: 100%
//                 a
//                     position: absolute
//                     bottom: 1rem
//                     right: 0
//                     display: flex
//                     flex-direction: row
//                     align-items: center
//                     padding: 0.5rem
//                     background-color: #437BA0
//                     color: #FFFFFF
//                     border: none
//                     font-weight: bold
//                     letter-spacing: 1px
//                     font-size: 1.1em
//                     text-decoration: none
//                     &>img
//                         height: 11px
//                         margin-left: 0.5rem
//             .Description
//                 display: flex
//                 flex-direction: column
//                 height: 50%
//                 padding: 1rem
//                 box-sizing: border-box
//                 transition: height 0.2s
//                 position: absolute
//                 bottom: 0
//                 z-index: 99
//                 h4
//                     margin: 0rem
//                 p
//                     height: calc(100% - 18px)
//                     overflow: hidden
//                     margin: 0
//                     margin-top: 0.5rem
//             label
//                 position: absolute
//                 bottom: 0
//                 height: 200px
//                 width: 100%
//                 z-index: 100
//             input[type=checkbox]
//                 position: absolute
//                 bottom: 0
//                 margin: 0
//                 height: 200px
//                 width: 100%
//                 display: none
//                 &:checked
//                     height: 100%
//                 &:checked ~ label
//                     height: 100%
//                 &:checked ~ .Description
//                     height: 100%
//                 &:checked ~ .Image
//                     opacity: 0%
//             .Fade
//                 background-image: linear-gradient(to bottom, transparent, white)
//                 position: absolute
//                 bottom: 0
//                 height: 4rem
//                 width: 100%
//                 border-radius: 1rem
//                 z-index: 100

// @media (min-height: 521px)
// 	// show, read more
// 	.read-more
// 		visibility: hidden !important
// 		width: 0 !important
// 		height: 0 !important
// 	.read-details
// 		visibility: visible !important
// 		width: auto !important
// 		height: auto !important

// @media (max-width: 575px)
// 	// show, read details
// 	.read-more
// 		visibility: hidden !important
// 		width: 0 !important
// 		height: 0 !important
// 	.read-details
// 		visibility: visible !important
// 		width: auto !important
// 		height: auto !important

// @media (min-width: 576px)
// 	// show, read more
// 	.read-details
// 		visibility: hidden !important
// 		width: 0 !important
// 		height: 0 !important
// 	.read-more
// 		visibility: visible !important
// 		width: auto !important
// 		height: auto !important

// @media (max-height: 520px)
// 	// show, read details
// 	.read-more
// 		visibility: hidden !important
// 		width: 0 !important
// 		height: 0 !important
// 	.read-details
// 		visibility: visible !important
// 		width: auto !important
// 		height: auto !important

// Target firefox mobile portrait and landscape
@media (max-width: 900px) {
	@-moz-document url-prefix() {
		.ScrollCards {
			margin-bottom: 8px;
			.Card {
				&.Intro {
					.Instructions {
						p {
							font-size: 10px !important; } }
					.Settings {
						.Options {
							button {
								span {
									font-size: 10px; } } } } }
				.Content {
					.Image {
						a {
							font-size: 14px; } } } } }
		.read-details {
			font-size: 12px; } } }
