.Explore {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	background-color: white;
	z-index: 110;
	-webkit-user-select: none;
	-moz-user-select: none;
	.ExploreBackground {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: black; } }

@media screen and ( min-width: 768px ) {
	.Explore {
		.mobile-notice {
			display: none; } } }


