.LineAnimation {
    transition-property: all;
    transition-timing-function: cubic-bezier(0,1,.99,0);
    transition-duration: 1s;
    width: 100%;
    height: 8rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    // align-items: center
    .Line {
        position: relative;
        width: 4px;
        height: 4rem;
        background-color: #fff;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        -webkit-animation: linetrace 2s ease-in-out infinite;
        animation: linetrace 2s ease-in-out infinite;
        // .Ball
        //     position: absolute
        //     background-color: #fff
        //     width: 1rem
        //     height: 1rem
        //     border-radius: 50%
        //     -webkit-box-shadow: 0px 0px 15px 5px rgba(255,255,255, 0.5)
        //     box-shadow: 0px 0px 15px 5px rgba(255,255,255, 0.5)
        //     -webkit-animation: linetrace 2s ease-in-out infinite
 } }        //     animation: linetrace 2s ease-in-out infinite

@-webkit-keyframes linetrace {
    0% {
        transform: translateY(-100%);
        opacity: 0; }
    75% {
        transform: translateY(200%);
        opacity: 1; }
    to {
        transform: translateY(200%); } }

@keyframes linetrace {
    0% {
        transform: translateY(-100%);
        opacity: 0; }
    75% {
        transform: translateY(200%);
        opacity: 1; }
    to {
        transform: translateY(200%); } }
