.Loader {
    &::before {
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        filter: blur(5px); }
    .LoaderAnimationWrapper {
        .LoaderAnimation {
            width: 30px;
            height: 30px;
            position: relative;
            margin: auto;
            margin-bottom: 1rem;

            &::before, &::after {
                content: '';
                position: absolute; }
            &::before, &::after {
                border-radius: 50%;
                animation: {
                    duration: 1s;
                    timing-function: ease-in-out;
                    iteration-count: infinite; } }
            &::before {
                width: 16px;
                height: 16px;
                top: calc(50% - 8px);
                left: calc(50% - 8px);
                border-bottom-right-radius: 0;
                box-shadow: 0 0 0 2px #fff;
                background: radial-gradient(transparent 0, transparent 3px, #fff 3px);
                transform: rotate(45deg);
                animation-name: mapPin; }
            &::after {
                width: 4px;
                height: 2px;
                opacity: 0.8;
                top: 100%;
                left: calc(50% - 2px);
                background: #fff;
                animation-name: mapPinShadow; } } } }

@keyframes mapPin {
    50% {
        transform: rotate(45deg) translate(-50%, -50%); } }

@keyframes mapPinShadow {
    50% {
        transform: scaleX(3);
        opacity: 0.2; } }
