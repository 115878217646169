.AccountModal {
    .AccountContent {
        padding: 1rem 2rem 2rem 2rem;
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        width: 350px;
        background: white;
        z-index: 1;
        position: relative;
        .Close {
            top: -16px;
            right: -16px; }
        .Header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center; }
        .CurrentEmail {
            margin: 2rem 0;
            p:nth-child(2) {
                margin: 1rem 0; } }
        form {
            .Info {
                > div {
                    margin-top: 1rem;
                    label {
                        display: block; } } }
            .Subscriptions {
                h2 {
                    margin: 1rem 0; }
                div {
                    margin-bottom: 1rem;
                    input {
                        margin-top: 0.25rem; }
                    label {
                        font-size: 0.9rem;
                        font-weight: 300;
                        padding-left: 0.5rem; } } } } } }
