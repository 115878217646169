.IntroScreen {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: calc(100% + 400px);
    overflow: scroll;
    color: white;
    .ResponsiveImage {
        position: fixed; }
    .Loading {
        margin-top: 4rem; }
    .Scroller {
        position: absolute;
        z-index: 100;
        width: 100%;
        height: calc(100% + 400px);
        overflow: hidden; }
    // Fixed content container
    .Content {
        height: 100%;
        width: 100%;
        position: fixed;
        z-index: 99;
        top: 0;
        text-align: center;
        // background: linear-gradient(to bottom, rgba(#000, 0.6), rgba(#000, 0.5), rgba(#000, 0))
        background: black;
        // &.loading
        //     background: rgba(#000, 0.5)
        &.loading {
            .ContentWrapper {
                display: none; } }
        .LoaderWrapper {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0; }
        // Relative container
        .ContentWrapper {
            position: relative;
            width: 100%;
            height: 100%;
            .Description {
                width: 100%;
                position: absolute;
                bottom: -4rem; }
            .KnockoutText {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100vw;
                height: 100vh;
                z-index: 10;
                background-color: #000;
                mix-blend-mode: multiply;
                text-align: left;
                h1, h2 {
                    text-transform: uppercase;
                    color: #fff; }
                h1, h2 {
                    transform: rotate(-90deg); }
                h1 {
                    font-size: 8vw;
                    margin-left: -24vw;
                    margin-top: -4rem; }
                h2 {
                    font-size: 16vw; } }
            .HTMLVideo {
                background: #fff; } } } }

@media (min-width: 568px) {
    .IntroScreen {
        .Content {
            .ContentWrapper {
                .KnockoutText {
                    text-align: center;
                    h1, h2 {
                        transform: none;
                        margin: 0; }
                    h1 {
                        font-size: 17vw;
                        margin-top: -24vh; }
                    h2 {
                        font-size: 12vw; } } } } } }

@media (min-width: 576px) and (min-height: 600px) {
    .IntroScreen {
        .Content {
            .ContentWrapper {
                .Description {
                    bottom: 0;
                    .LineAnimation {
                        height: 12rem;
                        .Line {
                            height: 6rem; } } } } } } }

// @media (hover: none) and (pointer: coarse)
//     .IntroScreen
//         .Content
//             &>div
//                 &>div
//                     &>div:nth-of-type(2)
//                         .desktop
//                             display: none
//                         .touchscreen
//                             display: block
