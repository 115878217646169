// .FluidScroll
// 	position: relative
// 	background-color: transparent
// 	min-height: 100vh
// 	.overlay
// 		// padding-bottom: 100vh
// 		& > *
// 			color: white
// 			grid-column: 1 / 6
// 			padding: 0em 1em 100vh 1em
// 		.video
// 			margin: 0vh 1em
// 			height: 100vh
// 		.title
// 			padding: 0em 1em 15em 1em
// 			font-family: Averia Libre
// 		.paragraph
// 			font-family: Averia Libre
// 			font-size: 1.2em
// 			line-height: 1.6em
// 	.background
// 		.hide
// 			opacity: 0
// 		width: 100%
// 		height: 100vh
// 		top: 0
// 		position: absolute
// 		// position: fixed
// 		z-index: -1
// 		& > *
// 			position: fixed
// 			transition: opacity 0.5s
// 		.FullScreenColor
// 			height: 100%
// 			width: 100%
// 		.FullScreenImage
// 			height: 100%
// 			img
// 				height: 100%
// 	.progress-wrap
// 		transform-origin: 1em 1em
// 		transform: rotate(-90deg)
// 		width: 2.5em
// 		position: fixed
// 		top: 30px
// 		right: 20px
// 		.progress-circle
// 			fill: transparent
// 			stroke: #ffffff
// 			stroke-width: 4

// @media screen and ( min-width: 900px )
// 	.FluidScroll
// 		.overlay
// 			& > *
// 				grid-column: 1 / 8


.FluidScroll {
	height: 100%;
	overflow-y: scroll;
	.Background,
	.Image,
	.ExploreIntro,
	.Three60Image,
	.InstagramGrid {
		width: 100%;
		height: 100%; }
	.Background {
		z-index: 20;
		position: absolute;
		top: 0;
		> * {
			position: fixed;
			transition: opacity 0.35s; }
		.inactive {
			opacity: 0;
			pointer-events: none; }
		.Image {
			img {
				width: 100%;
				height: 100%;
				object-fit: cover; } }
		.ExploreIntro,
		.InstagramGrid {
			background-color: black; }
		.Three60Image {
			canvas {
				// width: 100%
				// height: 100%
				// object-fit: cover
				cursor: -webkit-grab, -moz-grab; }
			&:active {
				cursor: -webkit-grabbing, -moz-grabbing; } } }
	.Overlay {
		overflow-x: hidden;
		position: relative;
		z-index: 30;
		transition: opacity 0.3s, visibility 0.3s;
		&.hide {
			opacity: 0;
			// pointer-events: none
			visibility: hidden; }
		// padding: 0rem 2rem 20rem 2rem
		> * {
			grid-column: 1 / 6; }
		background-color: transparent;

		.Video,
		.ExploreIntro,
		.ImageDescription,
		.Three60ViewerOverlay, {
			margin-left: 2rem;
			width: 100%;
			min-height: 100vh; }
		.ExploreIntro {
			color: white;
			line-height: 1.7rem;
			h1 {
				margin-bottom: 5rem; } }
		.ImageDescription,
		.Three60ViewerOverlay {
			line-height: 1.5rem;
			h5 {
				border-radius: 1rem;
				padding: 1.5rem;
				background-color: #FFFFFF87; } }
		.Three60ViewerOverlay {
			margin-top: 10rem; }
		.InstagramGrid {
			grid-column: 1 / -1;
			margin-left: 0;
			min-height: 100vh;
			// padding: 0em 2em
			h3 {
				// border-radius: 1rem
				padding: 1.5rem;
				background: transparent;
				color: white;
 }				// background-color: #FFFFFF87
			// iframe
			// 	width: 50% !important
			.flex {
				overflow-x: scroll;
				padding-top: 2em;
				padding-left: 1em;
				&::-webkit-scrollbar {
					display: none; }
				.InstagramCard {
					position: relative;
					min-width: 250px;
					min-height: 460px;
					margin-right: 3em;
					input[type=checkbox] {
						display: none;
						&:checked {
							&~.invisibleLabel {
								height: 100%; }
							&~.Image {
								height: 0%;
								opacity: 0; }
							&~.Description {
								height: 100%;
								border-radius: 0.5rem;
								.Caption {
									overflow: hidden;
									height: calc(21 * 1.2em); }
								.readMoreCaption {
									display: none; } } } }
					.invisibleLabel {
						height: 0%;
						width: 100%;
						position: absolute;
						top: 0;
						z-index: 20; }
					.Image {
						height: 50%;
						width: 100%;
						transition: opacity 1s;
						opacity: 1;
						a,
						a img {
							display: block;
							width: 100%;
							height: 100%; }
						a {
							img {
								object-fit: cover;
								border-radius: 0.5rem 0.5rem 0rem 0rem; } } }
					.Description {
						position: absolute;
						bottom: 0;
						height: 50%;
						background-color: white;
						border-radius: 0rem 0rem 0.5rem 0.5rem;
						padding: 0em 0.5em 0em 0.5em;
						transition: height 0.4s;
						box-sizing: border-box;
						p, label {
							color: black; }
						.ProfileName {
							padding: 0.5em 0em 0.5em 0em; }
						.Caption {
							line-height: 1.2em;
							height: calc(8 * 1.2em);
							overflow: hidden; }
						label {
							position: absolute;
							bottom: 1em;
							left: 0.5em;
							text-decoration: underline; } } } } } }
	.Progress {
		position: fixed;
		z-index: 40;
		top: 0;
		height: 6px;
		background-color: #000000;
 } }		// transition: width 0.5s ease

@media screen and ( min-width: 900px ) {
	.FluidScroll {
		.Overlay {
			// padding: 0rem 3rem 10rem 7rem
			& > * {
				grid-column: 1 / 8; }
			.ExploreIntro,
			.ImageDescription,
			.Three60ViewerOverlay {
				h5 {
					line-height: 2rem;
					font-size: 1.4rem; } }
			.ExploreIntro {
				h1 {
					font-size: 2rem; } }
			.Video,
			.ExploreIntro,
			.ImageDescription,
			.Three60ViewerOverlay {
				margin-left: 5rem; }
			.InstagramGrid {
				h3 {
					font-size: 1.4em;
					padding-bottom: 3em;
					padding-left: 3rem; }
				.flex {
					padding-left: 3rem;
					.InstagramCard {
						min-width: 400px;
						min-height: 600px;
						margin-right: 3.5em;
						.Description {
							padding: 0em 1em 0em 1em;
							.ProfileName {
								padding: 1em 0em 1em 0em; }
							.Caption {
								height: calc(11 * 1.2em); }
							label {
								left: 1em; } } } } } } } }

