.SignUpModal {
	.Close {
		top: -16px;
		right: -16px; }
	.SignUpContent {
		padding: 1rem 2rem 2rem 2rem;
		border-radius: 7px;
		display: flex;
		flex-direction: column;
		width: 350px;
		background: white;
		z-index: 1;
		position: relative; }
	.SignUpForm {
		.Title {
			padding-top: 20px;
			padding-bottom: 10px;
			font-size: 1rem; }
		.LinkNote {
			display: flex;
			flex-direction: row;
			padding: 0.5rem;
			border-radius: 7px;
			background: #505050;
			color: white;
			margin-top: 1rem;
			cursor: default;
			span[aria-label=sparkles] {
				display: block;
				margin-right: 0.5rem;
				margin-left: 0.5rem;
				line-height: 2.5rem;
				font-size: 2rem; } }
		.SubscribeCheckboxWrapper {
			display: flex;
			margin-top: 1rem;
			input {
				margin-top: 0.25rem; }
			label {
				font-size: 0.9rem;
				font-weight: 300;
				padding-left: 0.5rem; } }
		button[type='submit'] {
			margin-bottom: 1rem; }
		input[type='email'], input[type='text'] {
			width: 100%;
			margin-top: 1rem; }
		.horizontal {
			display: none; }
		.ChangePage.horizontal {
			margin-top: 1rem; } }
	.SignUpSent {
		.SentMessage {
			p {
				margin-top: 2rem;
				&:first-child {
					margin-top: 0; } }
			input[type='submit'] {
				font-size: 1rem; } } } }

@media (orientation: landscape) and (min-width: 576px) and (max-width: 900px) {
	.SignUpModal {
		align-items: normal !important;
		.SignUpContent {
			width: 90vw;
			height: 72vh;
			margin-top: 1rem; }
		.SignUpForm {
			.horizontal {
				display: block; }
			.not-horizontal {
				display: none; }
			.ContentWrapper {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				flex-grow: 1;
				&> div {
					width: calc(50% - 1rem);
					input:first-child {
						margin-top: 0; }
					input:not(:first-child) {
						margin-top: 0.5rem; } }
				.LinkNote {
					display: flex;
					font-size: 0.75rem;
					line-height: 1.25rem; } } } } }

// Target firefox mobile portrait and landscape
@media (max-width: 900px) {
	@-moz-document url-prefix() {
		.SignUpModal {
			.SignUpForm {
				.Title {
					font-size: 12px; }
				.LinkNote {
					font-size: 12px;
					span[aria-label=sparkles] {
						font-size: 16px; } }
				.ChangePage {
					p {
						font-size: 10px; } }
				input[type='email'], input[type='text'] {
					font-size: 12px; }
				button[type='submit'] {
					margin-top: 16px; } }
			.SignUpSent {
				.SentMessage {
					p {
						font-size: 12px; } } } } } }

