.MysteryModal {
    .MysteryContent {
        width: 100%;
        height: 100%;
        z-index: 1;
        overflow: scroll;
        .MysteryWrapper {
            background: black;
            color: white;
            padding-top: 0;
            p {
                line-height: 1.8rem; }
            &>div {
                margin-bottom: 2rem;
                padding: 0 2rem; }
            &>div:first-of-type {
                padding-top: 2rem; }
            &>div:last-of-type {
                padding-bottom: 2rem;
                margin-bottom: 0; }
            .Close {
                position: absolute;
                top: 10px;
                right: 10px; }
            .SupportBanner {
                position: sticky;
                top: 0;
                background: rgb(0,0,0);
                background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 100%);
                padding-bottom: 2rem;
                margin-bottom: 0;
                .SupportButton {
                    background: white; }
                p {
                    margin-bottom: 1rem;
                    b {
                        font-size: 16px; }
                    &.SupportDescription {
                        font-size: 10px;
                        line-height: 20px; } } }
            .Image {
                display: flex;
                justify-content: center;
                > div {
                    max-width: 400px;
                    height: 400px; } }
            .CTA {
                h2 {
                    margin-bottom: 1rem; }
                p {
                    margin-bottom: 1rem; }
                a {
                    color: white; } } } } }

@media (orientation: landscape) {
    .MysteryModal {
        .MysteryContent {
            .MysteryWrapper {
                .Image {
                    > div {
                        height: 250px; } } } } } }

@media (min-width: 568px) and (min-height: 520px) {
    .MysteryModal {
        .MysteryContent {
            max-width: 650px;
            max-height: 600px;
            overflow: scroll;
            .MysteryWrapper {
                position: relative;
                .Image {
                    > div {
                        height: 250px; } } } } } }
