.HTMLVideo {
	position: fixed;
	height: 100%;
	width: 100%;
	overflow: hidden;
	-webkit-user-select: none;
	-moz-user-select: none;
	&.remove-cursor {
		cursor: none; }
	.Video {
		// Full-bleed videos, but only if they are the correct aspect ratio of the screen!
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: 100% !important;
		width: 177.77777778vh !important; // 100 * 16 / 9
		min-width: 100%;
		min-height: 56.25vw; } } // 100 * 9 / 16

// @media (min-width: 325px) and (max-width: 576px) and (max-height: 667px)
// 	.HTMLVideo
// 		.Video
// 			height: 133.333333vh !important// 100 * 16 / 9

// @media (min-width: 576px) and (max-width: 991px) and (orientation: portrait)
// 	.HTMLVideo
// 		.Video
// 			height: 133.333333vh !important// 100 * 16 / 9
