.SandboxModal {
    .Close {
        top: -16px;
        right: -16px; }
    .SandboxContent {
        padding: 1rem 2rem 2rem 2rem;
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        width: 350px;
        background: white;
        z-index: 1;
        position: relative; } }
