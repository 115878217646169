.ScrollContent {
    height: 100%;
    position: relative;
    .Loader {
        position: fixed;
        z-index: 1001;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        p {
            color: white; } }
    .ScrollBlock {
        width: 100%;
        height: 100%;
        overflow: scroll;
        position: absolute;
        background: white;
        z-index: 999;
        display: none;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            display: none; }
        &.active {
            z-index: 1000;
            display: block;
            .TextContainer {
                p.TextContent {
                    animation: fadeIn 2s, transformUp 2s; } } }
        &.notext {
            .TextContainer {
                > div {
                    padding-bottom: 105vh;
                    -webkit-mask-image: none;
                    mask-image: none; } } }
        &.outro {
            .TextContainer {
                > div {
                    -webkit-mask-image: none;
                    mask-image: none;
                    p.TextContent {
                        padding-top: 20vh;
                        padding-bottom: 0;
                        animation: fadeIn 2s; } } } }
        .ScrollBlockContentWrapper {
            min-height: 150%;
            width: 100%;
            background: black; }
        .TextContainer {
            width: 100%;
            height: 100vh;
            overflow: hidden;
            position: fixed;
            display: flex;
            z-index: 2;
            scrollbar-width: none;
            -ms-overflow-style: none;
            &::-webkit-scrollbar {
                display: none; }
            > div {
                pointer-events: none;
                overflow-y: scroll;
                -webkit-mask-image: linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 60%, rgba(0,0,0,0) 100%);
                mask-image: linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 60%, rgba(0,0,0,0) 100%);
                padding-bottom: 50vh;
                width: 100%;
                scrollbar-width: none;
                -ms-overflow-style: none;
                &::-webkit-scrollbar {
                    display: none; }
                p.TextContent, .ScrollMoreWrapper {
                    width: 100%;
                    min-height: 100px; }
                p.TextContent {
                    padding: 75vh 3.5rem 0 4rem;
                    font-size: 1.2rem;
                    line-height: 2.4rem;
                    color: white;
                    transition: opacity 2s;
                    &.hide {
                        opacity: 0.05; } }
                .BackToTop {
                    display: block;
                    margin: 0 auto;
                    color: white;
                    margin-top: 4rem;
                    border-color: white; } } }
        .ChildrenContainer {
            height: 100%;
            width: 100%;
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
            pointer-events: none;
            &.withEvents {
                background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 87%, rgba(0,0,0,0) 100%);
                padding-bottom: 5em;
                height: auto;
                pointer-events: initial;
                z-index: 1000;
                & + div {
                    pointer-events: none;
                    z-index: 0; } }
            & > * {
                pointer-events: inherit; } }
        &.notext {
            .TextContainer {
                padding-top: 0; } } }
    .OutroScrollBlock {
        .IconGrid {
            display: flex;
            flex-wrap: wrap;
            max-width: 400px;
            margin: 0 auto;
            margin-bottom: 2rem;
            .Item {
                width: 33%;
                margin-bottom: 2rem;
                text-align: center;
                cursor: pointer;
                .IconWrapper {
                    img {
                        max-height: 40px; } }
                .IconLabel {
                    font-size: 0.75rem;
                    line-height: 1rem; } } }
        .FeedbackForm {
            .Thumbs {
                text-align: center;
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-bottom: 2rem;
                > div {
                    width: 60px;
                    height: 60px;
                    max-width: 50%;
                    padding: 0.25rem;
                    border-radius: 50%;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: center;
                    img {
                        cursor: pointer;
                        width: 40px; }
                    &:first-child {
                        margin-right: 2rem; }
                    &.selected {
                        border: solid white 2px; } } }
            h4 {
                margin-bottom: 2rem;
                line-height: initial; }
            .FormWrapper {
                transition: max-height 1s;
                max-height: 0px;
                overflow: hidden;
                &.show {
                    max-height: 1000px; }
                label {
                    display: block;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    margin-bottom: 1rem;
                    p {
                        margin-bottom: 1rem; } }
                textarea {
                    width: 100%;
                    height: 150px;
                    padding: 1rem; }
                button[type='submit'] {
                    display: block;
                    margin-left: auto;
                    margin-right: auto; }
                .Submitted {
                    animation: fadeIn 1s forwards;
                    display: block;
                    font-size: 1rem;
                    text-align: center;
                    height: 250px;
                    padding: 100px 0; } } }
        .NextLocation {
            margin-top: 2rem;
            .ImageWrapper {
                width: 100%;
                max-width: 400px;
                height: 250px;
                position: relative;
                color: white;
                .LocationImage {
                    width: 100%;
                    height: 100%;
                    object-fit: cover; }
                .ImageLabel {
                    position: absolute;
                    top: 0;
                    padding: 0 5px;
                    .Label {
                        font-size: 0.75rem;
                        line-height: 0.5rem;
                        padding: 0;
                        margin: 0;
                        cursor: pointer; }
                    h4 {
                        line-height: initial; } }
                .ExploreButton {
                    img {
                        height: 64px;
                        position: absolute;
                        top: calc(50% - 16px);
                        right: calc(50% - 32px); } } } } } }

.mask {
    .ResponsiveImage {
        .MobileImage {
            -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 50%, rgba(0,0,0,0) 69%);
            mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 50%, rgba(0,0,0,0) 69%); } } }

@media (orientation: landscape) {
    .ScrollContent {
        .ScrollBlock {
            &:not(.notext) {
                .TextContainer {
                    background: linear-gradient(to right, rgba(0,0,0, 0.7), rgba(0,0,0, 0.5), rgba(0,0,0, 0.2) 30%, rgba(0,0,0, 0) 40%, rgba(0,0,0, 0));
                    > div {
                        -webkit-mask-image: none;
                        mask-image: none;
                        padding-bottom: 95vh;
                        p.TextContent {
                            width: 400px; } } } } }
        .OutroScrollBlock {
            .NextLocation {
                position: fixed;
                right: 0;
                top: 0;
                min-width: 168px;
                margin-top: 0;
                height: 100%;
                width: calc(100% - 400px);
                display: flex;
                align-items: center;
                justify-content: center;
                padding-right: 3rem;
                .ImageWrapper {
                    height: 300px;
                    width: 300px;
                    .ImageLabel {
                        padding: 0 20px; } } } } } }


@media (orientation: landscape) and (max-width: 800px) {
    .ScrollContent {
        .ScrollBlock {
            &:not(.notext) {
                .TextContainer {
                    > div {
                        p.TextContent {
                            width: 350px;
                            padding-right: 2.5rem; } } } } }
        .OutroScrollBlock {
            .NextLocation {
                width: calc(100% - 350px);
                .ImageWrapper {
                    width: 250px;
                    height: 250px; } } } } }

@media (orientation: portrait) and (min-width: 521px) {
    .ScrollContent {
        .OutroScrollBlock {
            .NextLocation {
                .ImageWrapper {
                    margin: 0 auto; } } } } }


@media (orientation: landscape) and (min-width: 900px) {
    .ScrollContent {
        .ScrollBlock {
            &:not(.notext) {
                .TextContainer {
                    > div {
                        p.TextContent {
                            width: 475px; } } } } }
        .OutroScrollBlock {
            .NextLocation {
                width: calc(100% - 475px);
                .ImageWrapper {
                    width: 400px;
                    height: 400px; } } } } }

@media (min-width: 835px) {
    .ScrollContent {
        .ScrollBlock {
            .ChildrenContainer {
                &.withEvents {
                    background: transparent;
                    margin-left: 475px;
                    top: 0;
                    bottom: 0;
                    overflow: scroll;
                    & + div {} } } } } }

// Target firefox
@-moz-document url-prefix() {
    .ScrollContent {
        .ScrollBlock {
            overflow: hidden;
            .TextContainer {
                overflow: hidden;
                &> div {
                    mask-image: linear-gradient(to top, black 0%, black 10%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0) 100%);
                    &::after {
                        content: '';
                        display: block;
                        height: 105vh; } } }
            &.notext {
                .TextContainer {
                    overflow: auto; } } } } }

// Target firefox mobile portrait and landscape
@media (max-width: 900px) {
    @-moz-document url-prefix() {
        .ScrollContent {
            .ScrollBlock {
                .TextContainer {
                    > div {
                        p.TextContent {
                            font-size: 14px;
                            padding-left: 92px;
                            padding-right: 74px;
                            line-height: 28px; } } } }
            .OutroScrollBlock {
                .FeedbackForm {
                    .FormWrapper {
                        label {
                            font-size: 14px;
                            line-height: 28px; } } } } } } }
