.Scroll {
	.transition {
		position: fixed;
		height: 100%;
		width: 100vw;
		transition: background-color 0.7s;
		&.fade-black {
			background-color: black;
			z-index: 10; } }
	.bottom,
	.top {
		position: absolute;
		height: 1px; }
	.bottom {
		bottom: 0px; }
	.top {
		top: 0px; } }
