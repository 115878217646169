@import '../util/mixins';

.ActionMenu {
    width: 100%;
    position: fixed;
    margin-top: 1rem;
    box-sizing: border-box;
    z-index: 111;
    animation: slideDown 2s;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    .Links {
        display: flex;
        &>div {
            display: flex;
            flex-direction: row; }
        .Link {
            margin-right: 0.5rem;
            &:last-child {
                margin-right: 0; }
            &.small, &.big {
                img {
                    height: 32px; }
                span {
                    transition: opacity 1s; } }
            &.small, &.big {
                width: 75px;
                height: 75px;
                box-sizing: border-box;
                padding: 0.5rem;
                border-radius: 50%;
                justify-content: center;
                align-items: center;
                display: flex;
                background: rgba(255,255,255,0.8);
                &.white {
                    background: none;
                    padding: 0;
                    margin: 0;
                    width: unset;
                    height: 40px;
                    margin-bottom: 0.5rem;
                    justify-content: flex-end;
                    img {
                        margin: 0; }
                    span {
                        display: none; }
                    &:last-child {
                        margin-bottom: 0; } }
                >a, div {
                    display: inline-block;
                    font-size: 0.5rem;
                    text-decoration: none;
                    >* {
                        display: block;
                        margin: 0 auto;
                        text-align: center; } } } } }
    .FiltersList {
        margin-top: 1rem;
        width: 250px;
        padding: 0.5rem;
        padding-left: 0;
        border-radius: 0.5rem;
        transition: opacity 1s;
        animation: fadeIn 1s;
        b {
            // color: white
            text-shadow: 1px 1px 2px white; }
        .Tags {
            margin-top: 0.5rem;
            width: 200px;
            .Tag {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: rgba(255,255,255,0.8);
                border: 1px solid rgba(0,0,0,0.4);
                border-radius: 0.25rem;
                padding: 0rem 0.5rem;
                line-height: 2rem;
                font-size: 0.75rem;
                &>button {
                    margin-left: 1rem;
                    background-color: rgba(255,0,0,0.4) !important;
                    padding: 1px 6px;
                    position: relative !important; } } } }
    .WebViewLinkWrapper {
        width: 1px;
        overflow: hidden;
        animation: flexGrow 2s forwards;
        animation-delay: 2s;
        .Link {
            margin-left: 1rem;
            a {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-decoration: none;
                color: black;
                background: rgba(255,255,255,0.8);
                border-radius: 8px;
                padding: 4px 8px;
                height: 88px;
                max-width: 320px;
                overflow: hidden;
                font-size: 12px;
                img {
                    height: 32px;
                    margin-right: 8px; }
                span {
                    opacity: 0;
                    animation: fadeIn 1s forwards;
                    animation-delay: 4s; } } } }

    &.black {
        .Links {
            margin: 0 auto;
            &>div {
                &:first-child {
                    margin-right: 0.5rem;
                    flex-grow: 0.0001; }
                a {
                    color: black;
                    >:first-child {
                        filter: drop-shadow(1px 1px 2px white); }
                    >span {
                        text-shadow: 1px 1px 2px white; } } } }
        .FiltersList {
            margin-left: 1rem;
            opacity: 1; } }
    &.white {
        width: calc(100% - 2rem); // account for margin
        margin-left: 1rem;
        pointer-events: none; // turn off pointer events so that back button works
        .Links {
            flex-grow: 1;
            &>div {
                flex-direction: column;
                &:first-child {
                    margin-left: 1rem;
                    transition: flex-grow 2s 2s;
                    flex-grow: 1; }
                a {
                    pointer-events: all; // turn on pointer events for links
                    color: white;
                    >:first-child {
                        filter: drop-shadow(1px 1px 4px rgba(0,0,0,0.7)); }
                    >span {
                        opacity: 0.4;
                        text-shadow: 1px 1px 4px rgba(0,0,0,0.7); } } } }
        .WebViewLinkWrapper {
            &~.Links {
                flex-grow: 0; } }

        .FiltersList {
            opacity: 0; } } }

@media (min-width: 568px) {
    .ActionMenu {
        &.black {
            justify-content: start;
            margin-left: 1rem;
            .Links {
                margin: unset; }
            .FiltersList {
                flex-basis: 100%; } } } }

// Change buttons to circles on tablet
@media (min-width: 768px) {
    .ActionMenu {
        width: calc(100% - 250px - 3.1rem);
        &.black {
            .Links {
                width: 100%; } }
        .Links {
            justify-content: space-between;
            &>div:nth-child(2) {
                justify-content: flex-end; }
            .Link {
                &.big {
                    width: auto;
                    padding: 0;
                    background: none;
                    >a, div {
                        @include button-style;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        background: rgba(255,255,255,0.8);
                        img {
                            margin-right: 0.5rem; }
                        span {
                            font-size: 1rem; } } } } } }
				&.TipUs, &.Subscribe {
					min-width: 120px;
					&>div {
						position: relative; }
					&>div:hover {
						background: rgba(255,255,255,1); }
					&>div:hover::after {
						@include button-style;
						content: '';
						position: absolute;
						top: -1px;
						left: -1px;
						bottom: -1px;
						right: -1px;
						border-width: 2px !important;
						box-sizing: border-box; } } }

@media (min-width: 1920px) {
	.ActionMenu {
		width: calc(100% - 250px - 11rem); } }

// Target firefox mobile portrait and landscape
@media (max-width: 900px) {
    @-moz-document url-prefix() {
        .ActionMenu {
            .Links {
                .Link {
                    &.small {
                        span {
                            font-size: 6px; } }
                    &.big {
                        div {
                            span {
                                font-size: 10px; } } } } } } } }
