.IntroMessage {
    position: absolute;
    min-height: 100px;
    width: 100%;
    background: black;
    z-index: 1001;
    color: white;
    padding: 1rem;
    animation: showIntroMessage 2s forwards;
    top: 0px;
    &.self-destructed {
        animation: hideIntroMessage 2s forwards; }
    .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 450px;
        margin: 0 auto;
        .text-wrapper {
            width: calc(100% - 100px); }
        button {
            position: static;
            color: white;
            width: 100px;
            height: 40px;
            margin-left: 1rem; } } }


@keyframes showIntroMessage {
    0% {
        transform: translateY(-100px);
        opacity: 0; }
    100% {
        transform: translateY(0);
        opacity: 1; } }

@keyframes hideIntroMessage {
    0% {
        transform: translateY(0);
        opacity: 1; }
    100% {
        transform: translateY(-100px);
        opacity: 0; } }
